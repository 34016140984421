import React, { useState, useEffect } from 'react';
import SpotifyWebApi from 'spotify-web-api-js';
import { generatePlaylistSuggestions } from './api';
import './App.css';
import { FaSpotify, FaPlay, FaPause, FaPlus, FaLeaf } from 'react-icons/fa';
import config from './config';

const spotifyApi = new SpotifyWebApi();

function App() {
  const [userInput, setUserInput] = useState('');
  const [playlist, setPlaylist] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [user, setUser] = useState(null);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [playlistLink, setPlaylistLink] = useState('');
  const [playlistName, setPlaylistName] = useState('');
  const [isNameGenerated, setIsNameGenerated] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.hash.substring(1));
    const token = params.get('access_token');
    
    if (token) {
      spotifyApi.setAccessToken(token);
      setIsLoggedIn(true);
      window.history.pushState("", document.title, window.location.pathname);
      
      spotifyApi.getMe().then(setUser).catch(err => {
        console.error('Error getting user data', err);
        setIsLoggedIn(false);
      });
    }
  }, []);

  const handleLogin = () => {
    const { SPOTIFY_CLIENT_ID, SPOTIFY_REDIRECT_URI } = config;
    const scopes = ['playlist-modify-private', 'user-read-private', 'user-read-email'];
    window.location = `https://accounts.spotify.com/authorize?client_id=${SPOTIFY_CLIENT_ID}&redirect_uri=${SPOTIFY_REDIRECT_URI}&scope=${scopes.join('%20')}&response_type=token`;
  };

  const generatePlaylist = async () => {
    setIsLoading(true);
    setError('');
    setPlaylistLink('');
    try {
      const suggestedSongs = await generatePlaylistSuggestions(userInput);
      const tracks = await Promise.all(suggestedSongs.map(song => 
        spotifyApi.searchTracks(`${song.artist} ${song.title}`).then(result => result.tracks.items[0])
      ));

      const uniqueTracks = [...new Set(tracks.filter(Boolean))];
      setPlaylist(uniqueTracks);
    } catch (error) {
      console.error('Error generating playlist:', error);
      setError('Failed to generate playlist. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const generatePlaylistName = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${config.API_BASE_URL}/generate-playlist-title`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userInput })
      });
      const { title } = await response.json();
      setPlaylistName(title.replace(/^["']|["']$/g, '').trim());
      setIsNameGenerated(true);
    } catch (error) {
      console.error('Error generating playlist title:', error);
      setError('Failed to generate playlist title. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const createSpotifyPlaylist = async () => {
    setIsLoading(true);
    try {
      const newPlaylist = await spotifyApi.createPlaylist(user.id, { name: playlistName, public: false });
      await spotifyApi.addTracksToPlaylist(newPlaylist.id, playlist.map(track => track.uri));
      setPlaylistLink(newPlaylist.external_urls.spotify);
      setIsNameGenerated(false);
    } catch (error) {
      console.error('Error creating Spotify playlist:', error);
      setError('Failed to create Spotify playlist. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  
  const togglePlay = (track) => setCurrentlyPlaying(currentlyPlaying === track.id ? null : track.id);

  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter' && userInput.trim() && !isLoading) {
      generatePlaylist();
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>AI Spotify Playlist Generator</h1>
        {isLoggedIn && user && (
          <div className="user-info">
            {user.images && user.images.length > 0 ? (
              <img src={user.images[0].url} alt="Profile" className="profile-pic" />
            ) : (
              <div className="profile-pic-placeholder">
                {user.display_name ? user.display_name[0].toUpperCase() : 'U'}
              </div>
            )}
            <span>{user.display_name || 'Spotify User'}</span>
          </div>
        )}
      </header>
      <main>
        {!isLoggedIn ? (
          <button className="spotify-button login-button" onClick={handleLogin}>
            <FaSpotify /> Login with Spotify
          </button>
        ) : (
          <div className="playlist-generator">
            <div className="input-container">
              <input 
                type="text" 
                value={userInput} 
                onChange={(e) => setUserInput(e.target.value)} 
                onKeyPress={handleInputKeyPress}
                placeholder="Describe your playlist mood or theme..."
                className="input-field"
              />
              <button 
                onClick={generatePlaylist} 
                disabled={isLoading || !userInput.trim()} 
                className="spotify-button generate-button"
              >
                {isLoading ? 'Generating...' : 'Generate Playlist'}
              </button>
            </div>
            {error && <p className="error-message">{error}</p>}
            {playlist.length > 0 && (
              <div className="playlist-results">
                <h2><FaLeaf className="monstera-icon" /> Generated Playlist</h2>
                <ul className="track-list monstera-list">
                  {playlist.map((track) => (
                    <li key={track.id} className="track-item monstera-item">
                      <div className="track-content">
                        <img src={track.album.images[1].url} alt="Album cover" className="album-cover" />
                        <div className="track-info">
                          <span className="track-name">{track.name}</span>
                          <span className="artist-name">{track.artists[0].name}</span>
                        </div>
                        {track.preview_url && (
                          <button className="play-button" onClick={() => togglePlay(track)}>
                            {currentlyPlaying === track.id ? <FaPause /> : <FaPlay />}
                          </button>
                        )}
                      </div>
                      {currentlyPlaying === track.id && (
                        <audio autoPlay src={track.preview_url}>
                          Your browser does not support the audio element.
                        </audio>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </main>
      {isLoggedIn && (
        <div className="fixed-bottom">
          {!isNameGenerated ? (
            <button 
              onClick={generatePlaylistName} 
              className="spotify-button generate-name-button" 
              disabled={isLoading || playlist.length === 0}
            >
              Generate Playlist Name
            </button>
          ) : (
            <div className="playlist-name-editor">
              <input 
                type="text" 
                value={playlistName} 
                onChange={(e) => setPlaylistName(e.target.value)}
                className="playlist-name-input"
              />
              <button 
                onClick={createSpotifyPlaylist} 
                className="spotify-button create-playlist-button" 
                disabled={isLoading || !playlistName.trim()}
              >
                <FaPlus /> {isLoading ? 'Creating...' : 'Save to Spotify'}
              </button>
            </div>
          )}
          {playlistLink && (
            <div className="playlist-link clearfix">
              <p>Your playlist has been created! <a href={playlistLink} target="_blank" rel="noopener noreferrer">Open in Spotify</a></p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default App;