const config = {
  // Frontend URLs
  FRONTEND_URL: 'https://playlist.optagonen.se',
  
  // Backend URLs
  API_BASE_URL: 'https://playlist.optagonen.se/api',
  
  // Spotify API
  SPOTIFY_CLIENT_ID: process.env.REACT_APP_SPOTIFY_CLIENT_ID,
  SPOTIFY_REDIRECT_URI: 'https://playlist.optagonen.se',
  
  // Other configurations
  PLAYLIST_SIZE: parseInt(process.env.REACT_APP_PLAYLIST_SIZE) || 25,
};

// Validate required environment variables
const requiredEnvVars = ['REACT_APP_FRONTEND_URL', 'REACT_APP_API_BASE_URL', 'REACT_APP_SPOTIFY_CLIENT_ID', 'REACT_APP_SPOTIFY_REDIRECT_URI'];

requiredEnvVars.forEach(varName => {
  if (!process.env[varName]) {
    throw new Error(`Missing required environment variable: ${varName}`);
  }
});

export default config;
