import config from './config';

export const generatePlaylistSuggestions = async (userInput) => {
  try {
    const response = await fetch(`${config.API_BASE_URL}/generate-playlist`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userInput }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error generating playlist suggestions:', error);
    throw new Error('Failed to generate playlist suggestions');
  }
};
